(function ($) {
  Drupal.behaviors.productRating = {
    attach: function (context) {
      return;
      /*
      //Moved to sites/labseries/themes/labseries_base/js/site/power_reviews.js until POWERREVIEWS.display.render can be called multiple times.
      if ( typeof POWERREVIEWS != 'undefined' && typeof Drupal.settings.power_reviews != 'undefined' ) {
        var pr_products = [];
        var $products = $('.js-product', context);

        $.each( $products, function( i, val ) {
          var product = prodcat.data.getProduct( $(val).data('product-id') );
          var pr_reviewsnippet_id = 'pr-reviewsnippet-' + product.PROD_BASE_ID;
          var $pr_reviewsnippet = $('#' + pr_reviewsnippet_id, context);

          if ( $pr_reviewsnippet.length && !($pr_reviewsnippet.hasClass('reviews-processed') ) ) {
            $pr_reviewsnippet.addClass('reviews-processed');

            var page_id = Drupal.settings.power_reviews.page_id || product.PROD_BASE_ID;
            var review_wrapper_url = ( Drupal.settings.power_reviews.review_wrapper_url || '/review/create' ) + '?page_id=' + page_id;

            pr_products.push({
              api_key: Drupal.settings.power_reviews.api_key,
              locale: Drupal.settings.power_reviews.locale,
              merchant_group_id: Drupal.settings.power_reviews.merchant_group_id,
              merchant_id: Drupal.settings.power_reviews.merchant_id,
              page_id: page_id,
              review_wrapper_url: review_wrapper_url,
              components: {
                ReviewSnippet: pr_reviewsnippet_id
              }
            });
          }
        });

        POWERREVIEWS.display.render( pr_products );
      }*/
    }
  };
})(jQuery);
